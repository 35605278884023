@import '../../../themes/variables.scss';

.R2R3Wrapper {
  background-color: $medium-grey;
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;

  .contentBlock {
    padding: 15px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
