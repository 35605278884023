.wrapper {
  width: 200px;
  height: 200px;
  margin-top: -50px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 2px solid black;
  }
}
