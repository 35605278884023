@import '../../../themes/generated/variables.base.scss';
@import '../../../themes/variables.scss';

.wrapper {
  .filtersList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      padding: 10px;
      border-bottom: 3px solid $light-purple;

      &.active {
        border-bottom: 3px solid $base-accent;

        a {
          color: $base-accent;
        }
      }

      a {
        color: $icon-color-gray;
        cursor: pointer;
      }
    }
  }
}

.toggleButton {
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;

  h6 {
    margin-right: 10px;
    font-size: 15px;
  }
}

.customDateBox {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  p {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.customDateDisplay {
  margin-top: 12px;
  margin-left: 16px;
  font-weight: bold;
  opacity: 0.6;

  div:hover {
    cursor: pointer;
  }
}
