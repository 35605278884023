@import '../../../themes/generated/variables.base.scss';

.mainContainer {
  background-color: $base-bg;

  .proceduresContainer {
    display: flex;
    gap: 12px;
    flex-grow: 1;
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consultationTitle {
  font-size: 18px;
  font-weight: 600;
  color: $base-text-color;
  margin-top: 12px;
}
