@import '../../themes/generated/variables.base.scss';

.link {
  text-align: center;
  font-size: 16px;
  font-style: normal;

  a {
    text-decoration: none;
  }
}

.formText {
  margin: 10px 0;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
}

.passwordEditor {
  width: 270px;
}

.showHiddenIcon {
  margin-left: 107px;
  margin-top: 15px !important;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .passwordEditor {
    width: 100%;
  }

  .showHiddenIcon {
    margin-left: 0;
    margin-top: 10px;
  }
}
