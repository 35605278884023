@import '../../../themes/generated/variables.base.scss';

.mainContainer {
  background-color: $base-bg;

  .informedContainer {
    display: flex;
    gap: 20px;
    flex-grow: 1;
  }
}
