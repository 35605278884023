.container {
  background-color: white;
  padding: 20px;

  h5 {
    margin-top: 0;
  }

  .formwrapper {
    width: 70%;
    margin-bottom: 30px;
  }

  .personalDetails {
    display: flex;
    justify-content: space-between;
  }
}
