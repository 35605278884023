@import '../../themes/generated/variables.base.scss';

.submitButton {
  margin-top: 10px;
}

.loginLink {
  color: $base-accent;
  font-size: 16px;
  text-align: center;
}
