@import '../../themes/variables.scss';

.tabsContainer {
  max-width: 1200px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border: $panel-border;
  border-radius: 6px;

  .dx-tabs {
    background: transparent;
  }
}
