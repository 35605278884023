@import './themes/generated/variables.base.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;

  section {
    padding: 16px;
  }

  .content {
    line-height: 1.5;
    flex-grow: 1;
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

html,
body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

#client-action-button .dx-icon {
  font-size: 24px;
  color: $icon-color-gray;
}

// Create procedure form tab Styles

#add-procedure-tab-panel .dx-tabs-wrapper {
  display: inline-table;
  width: 100%;
  pointer-events: none;
}

#add-procedure-tab-panel-view .dx-tabs-wrapper {
  display: inline-table;
  width: 100%;
}

#add-procedure-tab-panel .dx-tab {
  width: 33.34%;
}

// Modal Styles

.dx-popup-normal {
  .dx-popup-title {
    background-color: $base-accent;
    color: $base-bg;
  }

  .dx-button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .dx-icon {
      color: $base-bg;

      &.dx-icon-remove {
        color: red;
      }
    }
  }
}

.dx-tabpanel .dx-tab {
  min-width: 200px;
}

#teamTableWrapper
  .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(
    .dx-first-row
  ):not(.dx-label-v-align) {
  padding-top: 0px;
}

#teamTableWrapper .dx-layout-manager .dx-field-item {
  padding-bottom: 0px;
  font-size: 12px;
}

.dx-form-group-caption {
  font-size: 16px;
}

.side-navigation-menu
  .menu-container
  .dx-treeview
  .dx-treeview-node-container
  .dx-treeview-node.dx-state-selected
  > .dx-treeview-item
  * {
  color: #e171f4 !important;
}

#procedureCard .dx-button-has-icon {
  min-width: 30px;
}

.dx-freespace-row {
  height: 0 !important;
}

.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(
    .dx-first-row
  ):not(.dx-label-v-align) {
  padding-top: 10px;
}

.dx-form-group-with-caption > .dx-form-group-content {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
}

.dx-fileuploader-input-wrapper::before {
  padding-top: 0;
  margin-top: -1em;
  padding-bottom: 0;
}

.dx-fileuploader-input-wrapper::after {
  clear: both;
  padding-bottom: 0em;
  margin-bottom: -1em;
  padding-top: 0;
}

.dx-field-item-label-location-top {
  display: block;
  padding: 3px 3px 3px;
}
