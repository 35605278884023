@import '../../themes/generated/variables.base.scss';

.passwordEditor {
  width: 100%;
  position: absolute;
}

.showHiddenIcon {
  margin-left: 90%;
  padding-top: 5px;
}

.Item {
  width: 90%;
}
