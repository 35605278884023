.header {
  padding-left: 16px !important;
}

.brandLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 40.5px;
    height: 32.25px;
  }
}

.title {
  font-size: 18px;
  margin-left: 5px;
}
