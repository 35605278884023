@import '../../dx-styles.scss';
@import '../../themes/generated/variables.additional.scss';

$sidenav-bg: #242933;
$sidenav-item-selected: hsl(262, 80%, 50%);
$sidenav-item-hover: #313641;
$sidenav-item-color: white;
$side-panel-min-width: 56px;

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;
  position: relative; /* Added for positioning the image */

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;
    background-color: $sidenav-bg;
    position: relative; /* Added for positioning the image */

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        color: white;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }

      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          margin: 12px 6px;
          border-radius: 8px;
          border: none;
          font-weight: normal;

          i {
            color: $sidenav-item-color !important;
            font-size: 22px;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
            border-radius: 8px !important;
            background-color: $sidenav-item-selected;

            i {
              color: $sidenav-item-color !important;
            }

            span {
              color: $sidenav-item-color !important;
            }
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: $sidenav-item-hover;
            border-radius: 8px !important;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
  }

  .bottom-image {
    position: absolute;
    bottom: 15%;
    left: 5%;
    width: 90%;
    height: auto;
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import '../../utils/patches.scss';
  @include menu-pre-init-patch;
}
