.passwordEditor {
  width: 100%;
  position: absolute;
  margin-top: 0 !important;
}

.showHiddenIcon {
  margin-left: 90%;
  margin-top: -15px !important;
  margin-bottom: 5px;
}

.passwordItem {
  margin-top: 0 !important;
}
