.deleteBtn {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px;
}

.contentMsg {
  font-size: 16px;
  margin-bottom: 30px;
}
.deleteYesNOBtn {
  margin: 10px;
}
