@import './src/themes/generated/variables.base.scss';
.main {
  overflow: scroll !important;
}
.group1 {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  margin-right: 10px;

  ul {
    list-style: none;
  }

  li:before {
    content: '\2022';
    margin-right: 0.5em;
  }
}

.group2 {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  margin-left: 10px;
}

.dataItem {
  display: flex;
  flex-direction: row;
}

.itemTitle {
  font-size: 14px;
  flex: 1;
  margin-left: 10px;
}

.itemValue {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  flex: 3;
  word-break: break-word;
}

.commentContainer {
  margin-top: -30px;
  margin-left: 25px;
}

.commentsTitle {
  font-size: 14px;
}

.comments {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.itemSwitch {
  margin-right: 10px;
}

.documentLink {
  margin-top: 20px;
}

.formContainer {
  padding: 20px 10px;
  height: 500px;
}

.hiddenTagBox {
  margin-top: 20px;
}

.submitButtons {
  float: right;
  margin-top: 5px;
}

:global {
  .removeIcon .dx-icon {
    fill: green;
  }
}

.subTitle {
  text-decoration: underline;
  color: gray;
}

.subAlign {
  position: absolute;
  margin-top: -30px;
}

.tableFont {
  width: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: 30px;
}
.tableFontTitleName {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tableTeam {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  height: auto;
  border-bottom: 1px solid #a8a8a8;

  :global(.dx-state-focused) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.teamSelectBox {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 350px;
  padding: 0;
  border: 0;
}

.scheduleBtn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 17px;
  p {
    margin-right: 10px;
  }
}

.approvalValue {
  margin: 0px;
  font-size: 15px;
}

.teamTableHeader {
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #a8a8a8;
}

.removeButton {
  float: right;
  size: 10px;
}

.checkListText {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
}

.checkListItem {
  min-width: 500px;
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.addButton {
  margin-right: 20px;
}

.subjectsCheckBox {
  margin-bottom: 10px;
}

.dateBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  :global(.dx-state-focused) {
    margin-bottom: 20px;
  }
}

.dateName {
  font-size: 16px;
}

.frequencySelectBox {
  width: 500px;
}

.customWeekdayContainer {
  display: flex;
  flex-direction: row;
  width: 500px;
}

.customWeekday {
  border: 2px solid black;
  border-radius: 100px;
  margin-right: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #eeeeee;
}

.customSelectedWeekday {
  border: 2px solid black;
  border-radius: 100px;
  margin-right: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #c7c6d9;
}

.renderButton {
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    box-shadow: none;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
  }
}

.table {
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  font-weight: bold;
  color: #00000090;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f8f8fe;
  color: #00000070;
}

.evenRow {
  background-color: #f2f7fb;
}

.oddRow {
  background-color: #e6ebfe;
}

.headerRow {
  background-color: #555;
  color: #fff;
}

.radioButtonWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 10px;

  input[type='radio'] {
    display: none;
  }

  label {
    display: inline-block;
    cursor: pointer;
    padding-left: 25px;
    position: relative;
    font-size: 16px;

    &:before {
      content: '';
      display: flex;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      position: absolute;
      left: 0;
      bottom: 1px;
      background-color: #fff;
      border: 2px solid $icon-color-gray;
      border-radius: 50%;
    }
  }

  input[type='radio']:checked + label:after {
    content: '';
    text-align: center;
    background: $base-accent;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
  }

  input[type='radio']:checked + label:before {
    border-color: $base-accent;
  }
}

.fileUploadContainer {
  display: flex;
  flex-direction: column;
}

.fileUploader {
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.uploadButton {
  margin-top: 10px;
  align-self: 'right';
  width: fit-content;
  align-self: flex-end;
}

.error {
  color: red;
}

.attachmentsListWrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  word-break: break-word;
}

.requiredText {
  font-size: 15px;
  margin-left: 10px;
}

.nextBtn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 5px;
  margin-right: 14px;
}

.deleteYesNOBtn {
  margin: 10px;
}
.deleteBtn {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px;
}
:global {
  .dx-toolbar-item-content {
    font-size: 18px;
  }

  .dx-popup-normal .dx-button-content {
    font-size: 14px;
  }
}
:global {
  .dx-theme-material-typography a {
    margin-left: 10px;
  }
  .dx-layout-manager .dx-field-item {
    margin-top: 10px;
  }
}

.dateDuration {
  margin-top: 10px;
}
