@import '../../../themes/generated/variables.base.scss';
@import '../../../themes/variables.scss';

.wrapper {
  background-color: $base-bg;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  min-width: 35px;
  flex-wrap: wrap;

  .actionButton {
    height: auto;

    :global(.dx-button-text) {
      font-size: 13px;
      text-transform: capitalize;
    }
  }

  .callForHelpButton {
    height: auto;

    .dx-button-content {
      width: 30px;
    }
  }

  &.critical {
    background-color: rgba($red, 0.09);
    border: 1px solid rgba($red, 1);
  }

  &.item {
    width: 32.52%;
  }

  .headerBlock {
    display: flex;
    gap: 5px;
    float: right;
  }

  .clientWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 25px;

    p {
      font-size: 12px;
      margin: 0;
      background-color: #ececec;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    div {
      display: flex;
      align-items: center;
      font-size: 12px;
      background-color: #fcf4fd;
      padding: 0;
      border-radius: 30px;

      i {
        margin-right: 2px;
      }
    }
  }

  h6 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
      color: rgb(209, 77, 229);
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 2px;
    }
  }

  .imagesBlock {
    display: flex;
    margin-bottom: 8px;
    margin-left: 4px;

    img {
      width: 30px;
      margin-left: -6px;
      border-radius: 60px;
      overflow: hidden;
      object-fit: cover;
      height: 30px;
      border: 1px solid $base-border-color;

      &:hover {
        cursor: pointer;
        z-index: 1;
        position: relative;
        border: 2px solid $icon-color-gray;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .actionButton {
      height: auto;

      :global(.dx-button-content) {
        padding: 6px 10px;
      }

      :global(.dx-button-text) {
        font-size: 10px;
        text-transform: capitalize;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .pendingApproval {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .pendingApprovalText {
    display: flex;
    align-items: center;
    background-color: #f6e8e8;
    border-radius: 30px;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;

    p {
      margin-left: 5px;
      font-size: 9px;
    }
  }
}
.reAssignDiffer {
  :global(.dx-toolbar-label) {
    max-width: fit-content;
    width: 100%;

    :global(.dx-toolbar-item-content) {
      font-size: 17px;
    }
  }
}
