@import '../../themes/generated/variables.base.scss';
@import '../../themes/variables.scss';

.contentWrapper {
  background-color: $base-bg;
  padding: 5px;
  border-radius: 6px;
  padding-inline: 10px;
  border: $panel-border;
}

[role='gridcell'] {
  padding: 4px 8px !important;
}

[role='columnheader'] {
  padding: 16px 8px !important;
}
