.filtersBlock {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.filtersBtn {
  order: 0;
}

.searchBox {
  width: 40%;
  order: 1;
}

.addBtn {
  order: 2;
}
