.imageBlock {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.userImage {
  display: flex;
  justify-content: center;
}
