@import '../../../themes/variables.scss';

.informedWrapper {
  background-color: $medium-grey;
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;

  .titleBlock {
    padding: 15px;
    background-color: $light-grey;
    font-size: 15px;
    font-weight: 600;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .contentBlock {
    padding: 15px;
    display: flex;
    gap: 1%;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
