@import '../../../themes/generated/variables.base.scss';

.mainContainer {
  background-color: $base-bg;

  .R2R3Container {
    display: flex;
    gap: 20px;
    flex-grow: 1;
    flex-basis: 100%;
  }
}
