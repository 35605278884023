@import '../../../themes/variables.scss';

.procedureColumn {
  background-color: $medium-grey;
  width: 100%;
  min-width: 200px;
  border-radius: 6px;
  margin-top: 8px;

  .titleBlock {
    padding: 10px;
    background-color: #f6f6f6;
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    line-height: 16px;
    padding-left: 12px;
  }

  .contentBlock {
    padding: 8px;
    min-height: 160px;
  }
}
