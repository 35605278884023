@import '../../themes/generated/variables.base.scss';

.pageTitle {
  h1 {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.sectionTitle {
  h2 {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 6px;
  }
}
